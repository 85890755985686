import { api, param2query } from "./api.service";

export const downloadRelatorioGlosas = (filtro) => {
  return api.get(
    'relatorio-glosas/download?' + param2query(filtro),
    { responseType: 'blob' }
  ).then((response) => {
    console.log(response)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'relatorio-glosas.xlsx');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};
