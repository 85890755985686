<template>
  <card-form>
    <div class="row mt-4">
      <f-selectsearch 
        label="Convênio"
        name="convenioId"
        ref="convenioId"
        v-model="model.convenioId"
        :multiple="false"
        :cols="3"
        :searchFunction="consultarConvenio"
        @select="addConvenio"
        v-validate="{required: true}"
        data-vv-as="Convênio"
        :modelErrors="errors"
      />
      <div class="col-md-5 col-xxl-3" data-vv-as="Data do envio">
        <fieldset class="form-group mb-3 form-group-input">
          <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Data do envio</legend>
          <div class="d-inline-flex align-items-start">
            <label>
              <date-picker
                name="periodoDe"
                v-model="model.periodoDe"
                v-validate="{ required: true }"
                data-vv-as="Data de início do envio"
                :first-day-of-week="1"
                lang="pt-BR"
                format="DD/MM/YYYY"
                :class="{
                  'form-control is-invalid pb-0 pt-0 ps-0': isDisplayErrors('periodoDe'),
                }"
              ></date-picker>
              <span class="text-danger" v-if="isDisplayErrors('periodoDe')">{{ errors.first('periodoDe') }}</span>
            </label>
            <span class="mx-1 pl-1 pr-1 pt-2"> a </span>
            <label>
              <date-picker
                name="periodoAte"
                v-model="model.periodoAte"
                v-validate="{ required: true }"
                data-vv-as="Data fim de envio"
                :first-day-of-week="1"
                lang="pt-BR"
                format="DD/MM/YYYY"
                :class="{
                  'form-control is-invalid pb-0 pt-0 ps-0': isDisplayErrors('periodoAte'),
                }"
              ></date-picker>
              <span class="text-danger" v-if="isDisplayErrors('periodoDe')">{{ errors.first('periodoDe') }}</span>
            </label>
          </div>
        </fieldset>
      </div>
      
      <f-selectsearch 
        label="Hospital(is)"
        name="hospitaisIds"
        ref="hospitaisIds"
        v-model="model.hospitaisIds"
        :multiple="true"
        :cols="4"
        :searchFunction="consultarHospital"
        @select="addHospital"
      />
      <f-input 
        name="lote" 
        v-model="model.lote" 
        data-vv-as="Lote"
        label="Lote"
        :modelErrors="errors"
        :cols="2"
      />
      <f-input 
        name="guia" 
        v-model="model.guia"
        data-vv-as="Guia"
        label="Guia"
        :modelErrors="errors"
        :cols="2"
      />
      <f-input 
        name="carteira" 
        v-model="model.carteira"
        data-vv-as="Carteira"
        label="Carteira"
        :modelErrors="errors"
        :cols="2"
      />
      <f-selectsearch 
        label="Tipo(s) de internação"
        name="tipoInternacaoIds"
        ref="tipoInternacaoIds"
        v-model="model.tipoInternacaoIds"
        valueField="codigo"
        :multiple="true"
        :cols="2"
        :searchFunction="listarTipoInternacao"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoInternacao"
      />
      <f-selectsearch 
        label="Efetivo/Urgência"
        name="caraterAtendimentoIds"
        ref="caraterAtendimentoIds"
        valueField="codigo"
        v-model="model.caraterAtendimentoIds"
        :cols="2"
        :multiple="true"
        :searchFunction="listarCaraterAtendimento"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addCaraterAtendimento"
      />
      <f-selectsearch 
        label="Tipo(s) de faturamento"
        name="tipoFaturamentoIds"
        ref="tipoFaturamentoIds"
        v-model="model.tipoFaturamentoIds"
        valueField="codigo"
        :multiple="true"
        :cols="2"
        :searchFunction="listarTipoFaturamento"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoFaturamento"
      />
    </div>

    <template #footer>
      <button v-if="$hasPerm('relAuditoriaRetrospectiva')" class="btn btn-success px-5" @click="gerarRelatorio" type="button">
        Gerar Relatório
      </button>
    </template>
  </card-form>
</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import FSelectsearch from '@/components/Form/SelectSearch';
import {
  downloadRelatorioGlosas
} from "@/services/relatorio-glosas.service";
import {
  pesquisarConvenio, pesquisarHospital,
  listarTipoInternacao, listarCaraterAtendimento, listarTipoFaturamento
} from "@/services/relatorios.service";

export default {
  components: { CardForm, FSelectsearch, },
  data() {
    return {
      listarTipoInternacao,
      listarCaraterAtendimento,
      listarTipoFaturamento,
      model: {
        uploadDe: '',
        uploadAte: '',
        convenioId: '',
        hospitaisIds: [],
        lote: '',
        guia: '',
        carteira: '',
        tipoInternacaoIds: [],
        caraterAtendimentoIds: [],
        tipoFaturamentoIds: [],
      },
    };
  },
  methods: {
    
    addCaraterAtendimento(escolha) {
      this.model.caraterAtendimentoIds = escolha.map(m => m.codigo);
      //this.model.caraterAtendimentoIds = escolha.id;
    },
    addConvenio(convenio) {
      console.log(convenio)
      this.model.convenioId = convenio.id; //convenio.map(m => m.id);
    },
    addHospital(hospital) {
      this.model.hospitaisIds = hospital.map(m => m.id);
    },
    addTipoFaturamento(tipoFaturamento) {
      this.model.tipoFaturamentoIds = tipoFaturamento.map(m => m.codigo);
    },
    addTipoInternacao(tipoInternacao) {
      this.model.tipoInternacaoIds = tipoInternacao.map(m => m.codigo);
    },
    consultarConvenio(query) {
      return pesquisarConvenio(query, this.conveniosIds);
    },
    consultarHospital(query) {
      return pesquisarHospital(query, this.hospitaisIds);
    },
    async gerarRelatorio() {
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        downloadRelatorioGlosas(this.model);
      }
    },
    isDisplayErrors(tag) {
      return this.errors && this.errors.has(tag);
    }
  },
};
</script>