var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _vm.$hasPerm("relAuditoriaRetrospectiva")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.gerarRelatorio }
                    },
                    [_vm._v(" Gerar Relatório ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-selectsearch", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            ref: "convenioId",
            attrs: {
              label: "Convênio",
              name: "convenioId",
              multiple: false,
              cols: 3,
              searchFunction: _vm.consultarConvenio,
              "data-vv-as": "Convênio",
              modelErrors: _vm.errors
            },
            on: { select: _vm.addConvenio },
            model: {
              value: _vm.model.convenioId,
              callback: function($$v) {
                _vm.$set(_vm.model, "convenioId", $$v)
              },
              expression: "model.convenioId"
            }
          }),
          _c(
            "div",
            {
              staticClass: "col-md-5 col-xxl-3",
              attrs: { "data-vv-as": "Data do envio" }
            },
            [
              _c(
                "fieldset",
                { staticClass: "form-group mb-3 form-group-input" },
                [
                  _c(
                    "legend",
                    {
                      staticClass: "bv-no-focus-ring col-form-label pt-0",
                      attrs: { tabindex: "-1" }
                    },
                    [_vm._v("Data do envio")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-inline-flex align-items-start" },
                    [
                      _c(
                        "label",
                        [
                          _c("date-picker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }"
                              }
                            ],
                            class: {
                              "form-control is-invalid pb-0 pt-0 ps-0": _vm.isDisplayErrors(
                                "periodoDe"
                              )
                            },
                            attrs: {
                              name: "periodoDe",
                              "data-vv-as": "Data de início do envio",
                              "first-day-of-week": 1,
                              lang: "pt-BR",
                              format: "DD/MM/YYYY"
                            },
                            model: {
                              value: _vm.model.periodoDe,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "periodoDe", $$v)
                              },
                              expression: "model.periodoDe"
                            }
                          }),
                          _vm.isDisplayErrors("periodoDe")
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("periodoDe")))
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("span", { staticClass: "mx-1 pl-1 pr-1 pt-2" }, [
                        _vm._v(" a ")
                      ]),
                      _c(
                        "label",
                        [
                          _c("date-picker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }"
                              }
                            ],
                            class: {
                              "form-control is-invalid pb-0 pt-0 ps-0": _vm.isDisplayErrors(
                                "periodoAte"
                              )
                            },
                            attrs: {
                              name: "periodoAte",
                              "data-vv-as": "Data fim de envio",
                              "first-day-of-week": 1,
                              lang: "pt-BR",
                              format: "DD/MM/YYYY"
                            },
                            model: {
                              value: _vm.model.periodoAte,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "periodoAte", $$v)
                              },
                              expression: "model.periodoAte"
                            }
                          }),
                          _vm.isDisplayErrors("periodoDe")
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("periodoDe")))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c("f-selectsearch", {
            ref: "hospitaisIds",
            attrs: {
              label: "Hospital(is)",
              name: "hospitaisIds",
              multiple: true,
              cols: 4,
              searchFunction: _vm.consultarHospital
            },
            on: { select: _vm.addHospital },
            model: {
              value: _vm.model.hospitaisIds,
              callback: function($$v) {
                _vm.$set(_vm.model, "hospitaisIds", $$v)
              },
              expression: "model.hospitaisIds"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "lote",
              "data-vv-as": "Lote",
              label: "Lote",
              modelErrors: _vm.errors,
              cols: 2
            },
            model: {
              value: _vm.model.lote,
              callback: function($$v) {
                _vm.$set(_vm.model, "lote", $$v)
              },
              expression: "model.lote"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "guia",
              "data-vv-as": "Guia",
              label: "Guia",
              modelErrors: _vm.errors,
              cols: 2
            },
            model: {
              value: _vm.model.guia,
              callback: function($$v) {
                _vm.$set(_vm.model, "guia", $$v)
              },
              expression: "model.guia"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "carteira",
              "data-vv-as": "Carteira",
              label: "Carteira",
              modelErrors: _vm.errors,
              cols: 2
            },
            model: {
              value: _vm.model.carteira,
              callback: function($$v) {
                _vm.$set(_vm.model, "carteira", $$v)
              },
              expression: "model.carteira"
            }
          }),
          _c("f-selectsearch", {
            ref: "tipoInternacaoIds",
            attrs: {
              label: "Tipo(s) de internação",
              name: "tipoInternacaoIds",
              valueField: "codigo",
              multiple: true,
              cols: 2,
              searchFunction: _vm.listarTipoInternacao,
              searchAllOptions: true,
              removeOptionsOnSelect: false
            },
            on: { select: _vm.addTipoInternacao },
            model: {
              value: _vm.model.tipoInternacaoIds,
              callback: function($$v) {
                _vm.$set(_vm.model, "tipoInternacaoIds", $$v)
              },
              expression: "model.tipoInternacaoIds"
            }
          }),
          _c("f-selectsearch", {
            ref: "caraterAtendimentoIds",
            attrs: {
              label: "Efetivo/Urgência",
              name: "caraterAtendimentoIds",
              valueField: "codigo",
              cols: 2,
              multiple: true,
              searchFunction: _vm.listarCaraterAtendimento,
              searchAllOptions: true,
              removeOptionsOnSelect: false
            },
            on: { select: _vm.addCaraterAtendimento },
            model: {
              value: _vm.model.caraterAtendimentoIds,
              callback: function($$v) {
                _vm.$set(_vm.model, "caraterAtendimentoIds", $$v)
              },
              expression: "model.caraterAtendimentoIds"
            }
          }),
          _c("f-selectsearch", {
            ref: "tipoFaturamentoIds",
            attrs: {
              label: "Tipo(s) de faturamento",
              name: "tipoFaturamentoIds",
              valueField: "codigo",
              multiple: true,
              cols: 2,
              searchFunction: _vm.listarTipoFaturamento,
              searchAllOptions: true,
              removeOptionsOnSelect: false
            },
            on: { select: _vm.addTipoFaturamento },
            model: {
              value: _vm.model.tipoFaturamentoIds,
              callback: function($$v) {
                _vm.$set(_vm.model, "tipoFaturamentoIds", $$v)
              },
              expression: "model.tipoFaturamentoIds"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }